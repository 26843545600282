import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Moteur from "../components/moteur"

export default ({
  pageContext: { title, desc, url, ville, address1, codepostal },
}) => {
  return (
    <Layout>
      <SEO
        title={`Hôtel ${title}`}
        description={`Hôtel pas cher à ${ville} - ${title}`}
      />
      <section className={"bg-white"}>
        <div className={"container"}>
          <Moteur />
        </div>
      </section>
      <div className={"container"} style={{ padding: "30px 0" }}>
        <h1>{title}</h1>
        <p>
          {address1}
          {codepostal} {ville}
        </p>
        <p>{desc}</p>
        <a href={url} className={"btn"}>
          En savoir plus
        </a>
      </div>
    </Layout>
  )
}
